import { getLocalData, saveLocalData } from '@easyview/request/lib/store';
import { getLocaleStore, isClient } from './core';

let isInApp = false;
let isSeller = false;
let env = 'production';

export function setIsInApp(inApp) {
  isInApp = !!inApp;
}

export function getIsInApp() {
  return isInApp;
}

export function setIsSeller(seller) {
  isSeller = !!seller;
}

export function getIsSeller() {
  return isSeller;
}

// 平台列表
export const platformList = [
  { name: 'goldhorse', key: 0, loadingIcon: '' }, // 金马 0
  { name: 'platform', key: 1, loadingIcon: '' }, //平台 1
];

// 平台配置，放机构id
const platformConfig = {
  production: { 1: [63] }, //生产
  dev: {},
  fat: {
    1: [131, 143],
  }, //测试
  uat: {},
  sim: {
    1: [4, 27, 52],
  }, //sim
  aws: {},
};

export const getPlatform = (institutionId) => {
  let localPlatform = 0;
  const platform = platformConfig[env];
  const platKeys = Object.keys(platform);
  platKeys.forEach((key) => {
    if (platform[key].includes(institutionId)) {
      localPlatform = +key || 0;
    }
  });
  saveLocalData('platformType', localPlatform);
  return platformList[localPlatform];
};

const testUrl = ['t-extramile-app.easyview.xyz'];
const simUrl = ['hu.extramile-app.easyview.xyz'];
export const isTest = function () {
  if (process.server) return false;
  const { hostname } = location;
  return testUrl.includes(hostname);
};
export const isSim = function () {
  if (process.server) return false;
  const { hostname } = location;
  return simUrl.includes(hostname);
};

const envMap = {
  0: 'production',
  1: 'dev',
  2: 'fat',
  3: 'uat',
  4: 'sim',
  5: 'aws',
};
export function setEnv(e = 0) {
  const _env = envMap[e];
  env = _env;
}

export function getEnv() {
  return env;
}

// 硬编码  DVP
export const vipConfig = () => {
  let vip = { taishin: [161] };
  if (getEnv() === 'dev') {
    vip = { taishin: [143] };
  } else if (getEnv() === 'fat') {
    vip = { taishin: [131, 143] };
  } else if (getEnv() === 'sim') {
    vip = { taishin: [14, 3, 4] };
  }
  return vip;
};

// 埋点上报
export const setLayer = (path, obj) => {
  if (isClient() && ['aws', 'production'].includes(getEnv())) {
    dataLayer.push({
      event: 'submit_click',
      action_path: path,
      ...obj,
    });
  }
};

export const downloadUrl = 'https://extramileapp.goldhorse.com.hk';

export const industryOptions = () => {
  return [
    {
      value: 'External Asset Manager (EAM)',
      key: 'EAM',
      id: 1,
    },
    {
      value: 'Family Office',
      key: 'EAM',
      id: 2,
    },
    {
      value: 'Private Bank',
      key: 'PWM',
      id: 3,
    },
    {
      value: 'Securities House',
      key: 'PWM',
      id: 4,
    },
    {
      value: 'Others',
      key: 'None',
      id: 5,
    },
  ];
};

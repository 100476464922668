export function isClient() {
  return typeof window !== "undefined" && typeof window.document === "object";
}

export const noop = () => {};

export const noopPromise = () => Promise.resolve();

export function createLink(href, blank = false) {
  if (!isClient()) return;
  const link = document.createElement("a");
  const evt = document.createEvent("HTMLEvents");
  evt.initEvent("click", false, false);
  link.href = `/${href}`;
  if (blank) {
    link.target = "_blank";
  }
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(href);
  }, 100);
}

export function saveLocaleStore(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getLocaleStore(key) {
  const data = localStorage.getItem(key);
  return JSON.parse(data);
}
